@font-face {
  font-family: "Montserrat";
  src: url("/public/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("/public/fonts/Montserrat-SemiBold.ttf") format("truetype");
}


@font-face {
  font-family: "Montserrat-Bold";
  src: url("/public/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-BoldItalic";
  src: url("/public/fonts/Montserrat-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraBoldItalic";
  src: url("/public/fonts/Montserrat-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Neuropolitical";
  src: url("/public/fonts/neuropolitical\ rg.ttf") format("truetype");
}

body {
  font-family: "Montserrat-Bold";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
